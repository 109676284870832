import {encodeToBase64Async} from '../utils/helpers'

// ------------------------------------
// Constants
// ------------------------------------

const UPDATE_SMILE_PHOTO = 'UPDATE_SMILE_PHOTO'
const UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION'
const UPDATE_COUNTY_PROVINCE = 'UPDATE_COUNTY_PROVINCE'
const UPDATE_PAYMENT_INFO = 'UPDATE_PAYMENT_INFO'
const UPDATE_PAYMENT_METHOD_ID = 'UPDATE_PAYMENT_METHOD_ID'
const RESET_ORDER = 'RESET_ORDER'

const initialState = {
  // step 1: select smile photo
  photo: null,
  description: '',
  // step 2: select country/province
  country: null,
  province: null,
  saveCard: false,
  paymentMethodId: null,
  // step 3: Confirmation
  currency: 'CAD',
  clientSecret: null,
  card: null,
}

// ------------------------------------
// Actions
// ------------------------------------

const updateSmilePhoto = (file) => (dispatch) =>
  dispatch({
    type: UPDATE_SMILE_PHOTO,
    payload: { name: file.name, size: file.size, type: file.type, preview: URL.createObjectURL(file),
      base64Data: encodeToBase64Async(file)
     },
  })

const updateDescription = (description) => (dispatch) =>
  dispatch({
    type: UPDATE_DESCRIPTION,
    payload: description,
  })

const updateCountryProvince =
  ({ country, province }) =>
  (dispatch) =>
    dispatch({
      type: UPDATE_COUNTY_PROVINCE,
      payload: {
        country,
        province,
      }
    })

const updatePaymentInfo =
  ({ currency, clientSecret, card, saveCard }) =>
  (dispatch) =>
    dispatch({
      type: UPDATE_PAYMENT_INFO,
      payload: {
        currency,
        clientSecret,
        card,
        saveCard,
      }
    })

const updatePaymentMethodId = (paymentMethodId) => (dispatch) =>
  dispatch({
    type: UPDATE_PAYMENT_METHOD_ID,
    payload: paymentMethodId,
  })

const resetOrder = () => (dispatch) =>
  dispatch({
    type: RESET_ORDER,
  })

export const actions = {
  updateSmilePhoto,
  updateDescription,
  updateCountryProvince,
  updatePaymentInfo,
  updatePaymentMethodId,
  resetOrder,
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [UPDATE_SMILE_PHOTO]: (state, { payload } ) => {
    const newState = {
        ...state,
        photo: payload,
    };
    return newState;
  },
  [UPDATE_DESCRIPTION]: (state, { payload }) => {
    const newState = {
      ...state,
      description : payload,
    };
    return newState;
  },
  [UPDATE_COUNTY_PROVINCE]: (state, { country, province }) => ({
    ...state,
    country,
    province,
  }),
  [UPDATE_PAYMENT_METHOD_ID]: (state, { paymentMethodId }) => ({
    ...state,
    paymentMethodId,
  }),
  [UPDATE_PAYMENT_INFO]: (
    state,
    { currency, clientSecret, card, saveCard },
  ) => ({
    ...state,
    currency,
    clientSecret,
    card,
    saveCard,
  }),
  [RESET_ORDER]: () => { 
    const newState = {
      ...initialState 
    };
    return newState;
  },
}

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = initialState, action = {}) {
  const handler = ACTION_HANDLERS[action.type]

  if (handler) {
    return handler(state, action)
  }

  return state
}
