import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import {
  getFirestore,
  connectFirestoreEmulator,
  documentId,
} from 'firebase/firestore'

import config from './config'

const firebaseApp = initializeApp(config.firebase)
// firebaseApp.analytics()

export const auth = getAuth(firebaseApp)
export const storage = getStorage(firebaseApp)
export const functions = getFunctions(firebaseApp)
export const firestore = getFirestore(firebaseApp)
export const fieldPathDocumentId = documentId

// eslint-disable-next-line no-restricted-globals
if (window.location.host === 'localhost') {
  let [host, port] = config.firebaseEmulator.functionsHost.split(':')
  connectFunctionsEmulator(functions, host, port)
  ;[host, port] = config.firebaseEmulator.firestoreHost.split(':')
  connectFirestoreEmulator(firestore, host, port)

  connectAuthEmulator(auth, config.firebaseEmulator.authHost)
}

export default firebaseApp
