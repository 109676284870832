import React, { Component } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import store from 'utils/store'
import { loadFontIcons } from 'components/FontIcon'
import config from 'utils/config'
import ToothboothApiProvider from 'api/chart/ToothboothApiProvider'
import { AuthProvider } from './context/AuthProvider'
import Router from './routes'

const stripePromise = loadStripe(config.stripe.apiKey)

class App extends Component {
  componentDidMount() {
    loadFontIcons()
  }

  render() {
    return (
      <Elements stripe={stripePromise}>
        <Provider store={store}>
          <AuthProvider>
            <ToothboothApiProvider>
              <Router />
              <ToastContainer theme="colored" position="bottom-right" />
            </ToothboothApiProvider>
          </AuthProvider>
        </Provider>
      </Elements>
    )
  }
}

export default App
