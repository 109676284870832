import icon from 'assets/images/icon.png'
import logo from 'assets/images/logo.png'
import dentrinoLogo from 'assets/images/dentrino-logo.png'
import clearCorrectLogo from 'assets/images/clearCorrect-logo.png'

import tbwSection from 'assets/images/TBW_section.jpg'
import ondemandSection from 'assets/images/OnDemand_section.jpg'

import toothboothStand from 'assets/images/toothbooth/standHeroTransp.png'
import toothboothOrthoFeature from 'assets/images/toothbooth/orthoMode.png'
import toothboothWhiteningFeature from 'assets/images/toothbooth/whitenMode.png'
import toothboothCosmeticFeature from 'assets/images/toothbooth/cosmeticMode.png'
import toothboothRehabFeature from 'assets/images/toothbooth/rehabMode.png'

import toothBoothInstalled1 from 'assets/images/toothbooth/installs/installed_example_001.jpg'
import toothBoothInstalled2 from 'assets/images/toothbooth/installs/installed_example_002.jpg'
import toothBoothInstalled3 from 'assets/images/toothbooth/installs/installed_example_003.jpg'

import sky from 'assets/images/sky.jpg'
import coin from 'assets/images/coin.png'
import hero from 'assets/images/hero.jpg'
import home from 'assets/images/home.png'
import smilingHero from 'assets/images/hero-smile.jpg'
import featureItem from 'assets/images/featureItem.jpeg'
import compareIcon from 'assets/images/compare_handle.png'
import checkboxRect from 'assets/images/checkbox_rect.png'
import formClearIcon from 'assets/images/form_clear_icon.png'
import featureItemContainer from 'assets/images/placeholder.jpg'

import transformeLogo from 'assets/images/templates/TransformeOrtho/partner_logo.png'
import sageLogo from 'assets/images/templates/SageDental/partner_logo.png'
import imageOrthoLogo from 'assets/images/templates/ImageOrtho/partner_logo.png'
import espireDentalLogo from 'assets/images/templates/EspireDental/partner_logo.png'
import pureOrthoLogo from 'assets/images/templates/PureOrtho/partner_logo.png'

import bearnLogo from 'assets/images/templates/Bearn/partner_logo.png'
import aspenLogo from 'assets/images/templates/Aspen/partner_logo.png'
import rizziLogo from 'assets/images/templates/RiziBR/partner_logo.png'
import suresmileLogo from 'assets/images/templates/Byte/suresmile_logo.png'
import sureSmileClinicLogo from 'assets/images/templates/Byte/partner_logo.png'
import sharedPracticesLogo from 'assets/images/templates/SharedPractices/partner_logo.png'

import dodHeroDesktop from 'assets/images/DoD-Hero-Example.png'
import dodHeroMobile from 'assets/images/DoD-Hero-Example-mobile.png'

import example1 from 'assets/images/examples/example1.jpeg'
import example2 from 'assets/images/examples/example2.jpeg'
import example3 from 'assets/images/examples/example3.jpeg'

import sidebyside1Lg from 'assets/images/examples/sidebyside-1-lg.jpg'
import sidebyside1Sm from 'assets/images/examples/sidebyside-1-sm.jpg'
import sidebyside2Lg from 'assets/images/examples/sidebyside-2-lg.jpg'
import sidebyside2Sm from 'assets/images/examples/sidebyside-2-sm.jpg'
import sidebyside3Lg from 'assets/images/examples/sidebyside-3-lg.jpg'
import sidebyside3Sm from 'assets/images/examples/sidebyside-3-sm.jpg'

import step1 from 'assets/images/guidelines/step1.png'
import step2 from 'assets/images/guidelines/step2.png'
import step3 from 'assets/images/guidelines/step3.png'
import step4 from 'assets/images/guidelines/step4.png'
import step5 from 'assets/images/guidelines/step5.png'
import step6 from 'assets/images/guidelines/step6.png'

import elo from 'assets/images/cards/elo.svg'
import jcb from 'assets/images/cards/jcb.svg'
import mir from 'assets/images/cards/mir.svg'
import amex from 'assets/images/cards/amex.svg'
import code from 'assets/images/cards/code.svg'
import visa from 'assets/images/cards/visa.svg'
import hiper from 'assets/images/cards/hiper.svg'
import alipay from 'assets/images/cards/alipay.svg'
import paypal from 'assets/images/cards/paypal.svg'
import diners from 'assets/images/cards/diners.svg'
import generic from 'assets/images/cards/generic.svg'
import maestro from 'assets/images/cards/maestro.svg'
import discover from 'assets/images/cards/discover.svg'
import unionpay from 'assets/images/cards/unionpay.svg'
import hipercard from 'assets/images/cards/hipercard.svg'
import mastercard from 'assets/images/cards/mastercard.svg'
import cartes_bancaires from 'assets/images/cards/cartes_bancaires.svg'

const images = {
  icon,
  logo,
  dentrinoLogo,
  clearCorrectLogo,

  tbwSection,
  ondemandSection,

  toothboothStand,
  toothboothOrthoFeature,
  toothboothCosmeticFeature,
  toothboothWhiteningFeature,
  toothboothRehabFeature,

  toothBoothInstalled1,
  toothBoothInstalled2,
  toothBoothInstalled3,

  coin,
  hero,
  compareIcon,
  featureItem,
  smilingHero,
  checkboxRect,
  formClearIcon,
  featureItemContainer,

  transformeLogo,
  sageLogo,
  imageOrthoLogo,
  espireDentalLogo,
  pureOrthoLogo,

  bearnLogo,
  aspenLogo,
  rizziLogo,
  suresmileLogo,
  sureSmileClinicLogo,
  sharedPracticesLogo,

  dodHeroDesktop,
  dodHeroMobile,

  example1,
  example2,
  example3,

  sidebyside1Lg,
  sidebyside1Sm,
  sidebyside2Lg,
  sidebyside2Sm,
  sidebyside3Lg,
  sidebyside3Sm,

  step1,
  step2,
  step3,
  step4,
  step5,
  step6,

  elo,
  jcb,
  mir,
  sky,
  amex,
  code,
  home,
  visa,
  hiper,
  alipay,
  diners,
  paypal,
  generic,
  maestro,
  discover,
  unionpay,
  hipercard,
  mastercard,
  cartes_bancaires,
}

export default images
