export default {
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  },
  firebaseEmulator: {
    firestoreHost: process.env.REACT_APP_FIRESTORE_EMULATOR_HOST,
    functionsHost: process.env.REACT_APP_FUNCTIONS_EMULATOR_HOST,
    authHost: process.env.REACT_APP_AUTH_EMULATOR_HOST,
    storageHost: process.env.REACT_APP_STORAGE_EMULATOR_HOST,
  },
  stripe: {
    apiKey: process.env.REACT_APP_STRIPE_KEY,
  },
  charts: {
    hasuraJWTKey: process.env.REACT_APP_HASURA_JWT_KEY,
    graphAPIUrl: process.env.REACT_APP_TOOTHBOOTH_GRAPHQL_API,
  },
}
